<template>
  <div class="home">
    <div class="swiper-all">
      <div class="swiper-title" v-if="!isMobile">
        <div class="title-flex">
          <!-- <div class="title-left"> -->
          <img :src="logSrc" class="title-left" />
          <!-- </div> -->
          <div class="title-right">
            <div @click="scrollToElement('section1')" id="section1">关于我们</div>
            <div @click="scrollToElement('section2')" id="section2">合作企业</div>
            <div @click="scrollToElement('section3')" id="section3">联系我们</div>
          </div>
        </div>
      </div>
      <swiper
        swiper
        ref="mySwiper"
        :mousewheel="false"
        :options="swiperOptions"
        class="home-medical-box"
        v-show="isMobile==false"
      >
        <swiper-slide class="benefitBox" v-for="(item, index) in medicalInfo" :key="index">
          <img v-lazy="item.url" :src="item.url" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <swiper
        swiper
        ref="mySwiper"
        :options="swiperOptions"
        class="home-medical-box"
        v-show="isMobile==true"
      >
        <swiper-slide class="h5-benefitBox" v-for="(item, index) in medicalInfo" :key="index">
          <img v-lazy="item.h5Url" :src="item.h5Url" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <!-- <swiper swiper ref="mySwiper1" :options="swiperOptions" class="home-medical-box" v-else>
        <swiper-slide class="h5-benefitBox" v-for="(item, index) in medicalInfo1" :key="index">
          <img :src="item.h5Url" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>-->
    </div>
    <ul class="web-ul" v-if="!isMobile">
      <li>
        <img :src="list[0].url" v-lazy="list[0].url" />
      </li>
      <li id="section1" ref="section1">
        <img :src="list[1].url" v-lazy="list[1].url" />
      </li>
      <li>
        <img :src="list[2].url" v-lazy="list[2].url" />
      </li>
      <li id="section2" ref="section2">
        <img :src="list[3].url" v-lazy="list[3].url" />
      </li>
      <li>
        <img :src="list[4].url" v-lazy="list[4].url" />
      </li>
      <li id="section3" ref="section3" class="web-section3">
        <img :src="list[5].url" v-lazy="list[5].url" />
        <a class="web-url" href="https://beian.miit.gov.cn/" ></a>
      </li>
    </ul>
    <ul class="h5-ul" v-else>
      <li>
        <img :src="h5List[0].url" v-lazy="h5List[0].url"/>
      </li>
      <li id="section1" ref="section1">
        <img :src="h5List[1].url" v-lazy="h5List[1].url"/>
      </li>
      <li>
        <img :src="h5List[2].url" v-lazy="h5List[2].url"/>
      </li>
      <li id="section2" ref="section2">
        <img :src="h5List[3].url" v-lazy="h5List[3].url"/>
      </li>
      <li>
        <img :src="h5List[4].url" v-lazy="h5List[4].url"/>
      </li>
      <li id="section3" ref="section3" class="h5-section3">
        <img :src="h5List[5].url" v-lazy="h5List[5].url"/>
        <a class="h5-url" href="https://beian.miit.gov.cn/"></a>
      </li>
    </ul>
  </div>
</template>

<script>
// 引入swiper样式
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
// 引入vue-awesome-swiper
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "home",
  props: {},
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      logSrc: require("../assets/img/web/log.png"),
      windowWidth: window.innerWidth,
      // Swiper options
      swiperOptions: {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        centeredSlides: true, // 活动滑块居中显示
        autoplay: true, // 开启自动播放
        pagination: {
          // 配置分页器
          el: ".swiper-pagination",
          clickable: true
        }
        // ...
      },
      medicalInfo: [
        {
          url: require("../assets/img/web/1.jpg"),
          h5Url: require("../assets/img/h5/0-1.jpg")
        },
        {
          url: require("../assets/img/web/2.jpg"),
          h5Url: require("../assets/img/h5/1-1.jpg")
        },
        {
          url: require("../assets/img/web/3.jpg"),
          h5Url: require("../assets/img/h5/1-2.jpg")
        },
        {
          url: require("../assets/img/web/4.jpg"),
          h5Url: require("../assets/img/h5/1-3.jpg")
        }
      ],
      medicalInfo1: [
        {
          h5Url: require("../assets/img/h5/0-1.jpg")
        },
        {
          h5Url: require("../assets/img/h5/1-1.jpg")
        },
        {
          h5Url: require("../assets/img/h5/1-2.jpg")
        },
        {
          h5Url: require("../assets/img/h5/1-3.jpg")
        }
      ],
      list: [
        {
          url: require("../assets/img/web/Frame56.jpg")
        },
        {
          url: require("../assets/img/web/Frame57.jpg")
        },
        {
          url: require("../assets/img/web/Frame58.jpg")
        },
        {
          url: require("../assets/img/web/Frame59.jpg")
        },
        {
          url: require("../assets/img/web/Frame60.jpg")
        },
        {
          url: require("../assets/img/web/Frame61.jpg")
        }
      ],
      h5List: [
        {
          url: require("../assets/img/h5/1.jpg")
        },
        {
          url: require("../assets/img/h5/2.jpg")
        },
        {
          url: require("../assets/img/h5/3.jpg")
        },
        {
          url: require("../assets/img/h5/4.jpg")
        },
        {
          url: require("../assets/img/h5/5.jpg")
        },
        {
          url: require("../assets/img/h5/6.jpg")
        }
      ],
      isMobile: window.innerWidth <= 768
    };
  },
  methods: {
    handleToUrl(url) {
      window.open(url)
    },
    scrollToElement(element) {
      const el = this.$refs[element];
      console.log(el);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      console.log(this.isMobile);
      // 更新窗口大小
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener("resize", this.handleResize);
  },

  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
<style lang="less" scoped>
.home {
  .swiper-all {
    width: 100%;
  }
  .home-medical-box {
    .h5-benefitBox {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100vh;
      }
    }
    .benefitBox {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .swiper-all {
    position: relative;
    .swiper-title {
      position: absolute;
      width: 100%;
      height: 75px;
      line-height: 75px;
      left: 0;
      top: 0;
      color: #fff;
      // background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      background: rgba(0,0,0,0.3);
      z-index: 33;
      .title-flex {
        display: flex;
        align-items: center;
        .title-left {
          // text-align: left;
          margin-left: 5%;
          // line-height: 75px;
          // >img{
          //   margin-top: 20%;
          // }
        }
        .title-right {
          display: flex;
          margin-left: auto;

          > div {
            width: 120px;
            margin-right: 10%;
            cursor: pointer;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .web-ul {
    width: 100%;
    img {
      width: 100%;
      height: 90%;
    }
    .web-section3{
      position: relative;
      .web-url{
        position: absolute;
        width: 9.5%;
        height: 5.5%;
        left: 38%;
        bottom: 14%;
        transform: translate(-50%,-50%);
        // border: 1px solid red;
        cursor: pointer;
        z-index: 22;
      }
    }
  }
  .h5-ul {
    width: 100%;
    img {
      width: 100%;
      height: 90%;
      display: block;
    }
    .h5-section3{
      position: relative;
      .h5-url{
        position: absolute;
        width: 28%;
        height: 2.5%;
        right: 1%;
        bottom: 1%;
        transform: translate(-50%,-50%);
        // border: 1px solid red;
        cursor: pointer;
        z-index: 22;
      }
    }
  }
}
</style>
