import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import  './assets/css/app.less'
import VueLazyLoad from 'vue-lazyload'
 
Vue.use(VueLazyLoad)

Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
